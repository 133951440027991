<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/knowledgeList' }">知识库列表</el-breadcrumb-item>
          <el-breadcrumb-item>内容详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="标题：">
                {{ form.title }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型：">
                {{ form.knowledgeType=='video'?'视频':'文档' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="分类：">
                {{ form.moduleId }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：">
                {{ form.source }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="摘要：">
                {{ form.contentDescribe }} 
              </el-form-item>
            </el-col>
          </el-row>
          
          <!-- 基础信息中类型为视频时，显示视频模块 -->
          <div v-show="form.knowledgeType=='video'">
            <div class="form-title" style="margin-top:20px;position:relative;">
              <div>视频</div>
              <div style="position:absolute;top:0;right:0;">
                <el-button type="primary" size="small" @click="findVideo">查看视频</el-button>
              </div>
            </div>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="视频地址：">
                  <el-link type="primary" :href="form.mediaFileUrl" target="_blank">{{form.mediaFileUrl}}</el-link>
                </el-form-item>
              </el-col>
            </el-row>
            <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="true" title="预览视频">
              <video width="100%" :src="form.mediaFileUrl" autoplay loop controls></video>
            </el-dialog> 
            <!-- <el-upload
                action="#"
                list-type="picture-card"
                :file-list="allVideoList"
                :on-change="uploadVideoChange"
                :on-preview="handlePictureCardPreview"
                accept=".mp4"
                :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                <video class="el-upload-list__item-thumbnail" :src="file.url" autoplay loop></video>
                <span class="el-upload-list__item-actions">
                    <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleVideoRemove(file)">
                    <i class="el-icon-delete"></i>
                    </span>
                </span>
                </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="true" title="预览视频">
              <video width="100%" :src="dialogVideoUrl" autoplay loop controls></video>
            </el-dialog>        
            <div class="prompt">注意：视频大小≤5MB，支持MP4、AVI、WMV、SWF格式</div> -->
          </div>
          <!-- 基础信息中类型为文档时，显示文档模块 -->
          <div v-show="form.knowledgeType=='document'">
            <div class="form-title">封面图</div>
            <div class="img-box">
              <el-image 
                class="images"
                :src="form.coverFileUrl"
                :preview-src-list="[form.coverFileUrl]">
              </el-image>
            </div>
            <div class="form-title" style="margin-top:20px;position:relative;">
              <div>文档</div>
              <div style="position:absolute;top:0;right:0;">
                <a :href="form.documentFileUrl" target="_blank">
                  <el-button type="primary" size="mini">查看文档</el-button>
                </a>
              </div>
            </div>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="文档地址：">
                  <el-link type="primary" :href="form.documentFileUrl" target="_blank">{{form.documentFileUrl}}</el-link>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-upload
              action="#"
              :file-list="allPdfFileList"
              :on-change="uploadFileChange"
              :on-remove="handlePdfFileRemove"
              accept=".pdf"
              :auto-upload="false">
            </el-upload> -->
          </div>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/knowledgeList')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {},
      row: {},
    };
  },
  methods: {
    findVideo() {
      this.dialogVisible = true
    },
    loadData() {
      this.loading = true
      this.$ajax.post("knowledgeDetail", {}, {}, this.row.id).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 获取栏目分类
    loadList() {
      this.loading = true
      this.$ajax.get("columnClassify").then((res) => {
        res.data.forEach(e => {
          if(e.id==this.form.moduleId) {
            this.$nextTick(()=>{
              this.form.moduleId = e.module
            })
          }
        });
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/knowledgeList')
      return
    }
    this.row = this.$route.params.row
    this.loadData()
    this.loadList()
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin: 20px 0;
}
.el-select {
  width: 100%;
}
.myQuillEditor {
  margin: 0 0 20px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 240px;
  height: 135px;
}
/deep/.el-upload--picture-card {
  width: 240px;
  height: 135px;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 240px;
    height: 135px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>